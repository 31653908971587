html,
body {
  direction: rtl;
  text-align: right;
  background: $bg-theme-color;
  font-family: "Tajawal", sans-serif;
  background-color: $bg-seconday-color !important;
}
html,
body {
  width: 100%;
  min-height: 100%;
}
#root,
.main-section {
  min-height: 100vh;
  position: relative;
}
.main-section {
  display: flex;
  max-width: 100%;
}
.text-theme-primary {
  color: $primary-theme-color;
}
.bg-theme-primary {
  background-color: $primary-theme-color;
}
.mw-50 {
  min-width: 50% !important;
}
.text-black {
  color: black;
}
.color-grayed {
  color: $header-color;
}
a.MuiButton-containedPrimary:hover {
  color: white;
}