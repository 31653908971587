.login-container {
  display: flex;
  flex-grow: 1;
  background: url("../../images/login-top-right.svg") 105% 20% no-repeat,
    url("../../images/login-top-left.svg") -10% 20% no-repeat,
    url("../../images/login-bottom-left.svg") -20% 80% no-repeat,
    url("../../images/login-bottom-right.svg") 95% 75% no-repeat;
  background-size: 200px, 200px, 300px, 75px;
  background-color: $primary-theme-color;
}
