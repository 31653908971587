$bg-theme-color: #fbfbfb;
$primary-theme-color: #4a90e4;
$header-color: #393939;
$testimonial-arrow-color: #597ae4;
$service-list-border-color: #e1e1e1;
$modal-title-color: #2c3e50;
$shadow-color: #c4c4c4;
$bg-seconday-color: #f6f7fb;
$side-bar-color: rgba(0, 0, 0, 0.54);

$title-font-size: 1.5rem;
$subtitle-font-size: 1.25rem;
$sm-font-size: 1.1rem;
$card-title-size: 1.2rem;
$card-details-size: 1rem;