.add-btn {
  color: $primary-theme-color;
  cursor: pointer;
  display: inline-block;
}
.dialog {
  padding-bottom: 1rem;
}
.dialog,
.dialog__btn {
  min-width: 80% !important;
}
.last-news__theader {
  background-color: darken($color: $bg-seconday-color, $amount: 5%);
}
.icon-link {
  font-size: 1rem;
}
.contact-table td:nth-of-type(1) {
  color: $primary-theme-color;
}
@media screen and (min-width: 768px) {
  .dialog,
  .dialog__btn {
    min-width: 50% !important;
  }
}
.dialog-close {
  position: absolute !important;
  top: 0;
  right: 0;
  padding: 1rem !important;
}
.home-slider-btn {
  cursor: pointer;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.home-slider-btn:hover {
  color: $primary-theme-color;
}
.loading-bar {
  background-color: $primary-theme-color;
  height: 3px;
  position: fixed;
  max-width: 100%;
  top: 0;
  right: 0;
  left: 0;
}
.news__image {
  max-height: 300px;
}
