.table {
  width: 100% !important;
  margin-bottom: 0 !important;
}
.table-responsive {
  display: block !important;
  width: 100% !important;
  overflow-x: auto !important;
  -webkit-overflow-scrolling: touch !important;
}
.table td {
  vertical-align: middle !important;
  border-top: none;
}
.table-fixed {
  min-width: 500px;
}
.slider__list {
  flex: 1 1 auto;
  overflow-y: auto;
  max-height: 300px;
}
