.card-dropdown {
  padding: 0 1rem;
  font-size: 1.05rem;
}
.card-dropdown-item {
  padding: 0.5rem;
  cursor: pointer;
}
.card-dropdown-item:not(:last-of-type){
  border-bottom: 1px solid $shadow-color;
}