.image-upload-container {
  max-width: 150px;
  max-height: 150px;
  margin: auto;
  padding: 0;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.image-upload,
.thumb-uploader {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  border-radius: 10px;
  cursor: pointer;
}
.image-upload__root,
.thumb-uploader__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(74, 144, 228, 0.3);
  border-radius: 10px;
}
.image-upload__img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-upload__rounded {
  border-radius: 10px;
}
