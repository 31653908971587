.messages-list {
  z-index: 5000;
}
.messages-list__menu {
  max-height: 300px;
  overflow: auto;
  min-width: 200px;
  max-width: 300px;
}
.message-item__text {
  position: relative;
  word-break: break-word;
  margin-top: 1rem !important;
  font-size: 1rem;
  text-align: right;
}
.messages-item{
  display: block !important;
  text-decoration: none !important;
  color: $header-color;
}