.side-bar__item {
  display: flex !important;
  align-items: center !important;
  padding-right: 1.5rem !important;
}
.side-bar__item-text {
  flex-grow: 0 !important;
  color: $header-color;
}
.selected .side-bar__item {
  background-color: $primary-theme-color !important;
  box-shadow: $shadow-color 2px 2px 2px, $shadow-color 2px 2px 2px;
  border-radius: 5px;
}
.selected .side-bar__item .side-bar__item-text {
  color: white;
}
.selected .side-bar__icon {
  color: white;
}
