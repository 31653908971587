.page-title {
  font-size: $title-font-size;
}
.page-subtitle {
  font-size: $subtitle-font-size;
}
.page-subtitle-lead {
  font-size: $sm-font-size;
}
.card__title {
  font-size: $card-title-size;
}
.card__subtitle {
  font-size: $card-details-size;
}
