.job-details__table td {
  padding: 0.5rem;
  font-size: 0.95rem;
  min-width: 150px;
}
.jobs-requests__search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  flex-wrap: wrap;
}
.jobs-requests__filter {
  display: flex;
  color: white;
  background-color: $primary-theme-color;
  padding: 0.5rem;
  border-radius: 5px;
  text-align: center;
  margin: 0;
  margin-top: 1rem;
  justify-content: center;
  width: 100%;
}
@media screen and(min-width:768px){
  .jobs-requests__filter{
    width: auto !important;
    margin-top: 0;
    margin-right: auto;
  }
}
.search-paper {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}