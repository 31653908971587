.media-dropzone {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 2px dashed var(--box-shadow-color);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px dashed $header-color;
}
.media-dropzone__help-text {
  margin: 0;
  color: #756e6e;
  padding: 1rem;
}
.media-dropzone__preview {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1rem;
  min-height: 105px;
  margin-bottom: 1rem;
}
.media-dropzone__preview-item {
  width: 100px;
  height: 100px;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}
